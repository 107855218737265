.header_part{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    padding-top: 40px !important;
}
.header_part h2{
    font-weight: 800 !important;
    color: var(--text-color-1) !important;
}
.header_part span{
    color: var(--text-color-1) !important;
}
.nri_card{
    background-color: #fff !important;
    border-radius: 7px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
    /* margin-top: -40px !important; */
}
.card_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    gap: 29px;
    padding-left: 35px;
}
.card_info p{
    color: var(--text-color-1) !important;
    font-size: 20px !important;
}
.card_info button{
    background-color: var(--default-bg-color) !important;
    text-transform: capitalize !important;
    color: #fff !important;
    font-size: 18px !important;
    padding-right: 50px !important;
}
.card_info button{
    background-color: var(--default-bg-color) !important;
    text-transform: capitalize !important;
    color: #fff !important;
    font-size: 18px !important;
    padding-right: 50px !important;
}
.card_info button svg{
    margin-right: 20px !important;
}
.we_provides{
    background-color: var(--text-color-1) !important;
    border-radius: 10px !important;
    padding: 16px !important;
    width: 75%;
    margin: auto;
    margin-top: 40px !important;
    text-align: center;
}
.we_provides_details{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 20px;
    color: #fff !important;
    margin-top: 30px !important;
    margin-bottom: 15px !important;
}
.we_provides_details svg{
    margin-right: 6px !important;
}
.post_enquiry{
    background-color: var(--default-bg-color) !important;
    text-transform: capitalize !important;
    color: #fff !important;
    padding: 10px 60px !important;
}
.sub_menus{
    justify-content: center;
    gap: 25px;
    background-color: var(--text-color-1) !important;
    padding: 12px 0 !important;
}
.sub_menus p{
    color:  #fff !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    cursor: pointer;
}
.connect_us{
    background-color: #568383 !important;
    padding: 30px 75px !important;
    border-bottom-left-radius: 28px !important;
    border-bottom-right-radius: 28px !important;
    color: #fff !important;
    font-size: 32px !important;
}
.connect_us_part svg{
    margin-right: 10px !important ;
}
.connect_details{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 22px 16px !important;
}
.connect_details_title{
    font-size: 24px !important;
    color: var(--default-background-color) !important;
    font-weight: 600 !important;
}
.register_btn{
    background-color:var(--default-background-color) !important ;
    color: #fff !important;
    text-transform: capitalize !important;
    margin-top: 20px !important;
    font-size: 18px !important;
}
.aboutus_card{
    background-color: var(--default-background-color) !important;
    border-radius: 7px !important;
    padding: 22px 22px 30px 22px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    height: 100% !important;
}
.aboutus_card h5{
    color: #fff !important;
}
.aboutus_card p{
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    text-align: center;
    opacity: 0.9;
}
.aboutus_two{
    margin: 20px 0 !important;
    background-color: #fff !important;
    padding: 16px !important;
    border-radius: 8px !important;
}
.all_counts{
    padding: 16px !important;
    background-color: #DEECEA !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center !important;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 20px;
}
.news_art{
    position: absolute;
    top: 120px;
    left: 120px;
    color: #fff !important;
    font-size: 40px !important;
    font-weight: 600 !important;
}
.news_part{
    background-color: #fff !important;
    border-radius: 6px !important;
    padding: 10px;
}
.news_part p{
    font-weight: 700 !important;
    margin: 7px 0 !important;
}
.news_part span{
    font-size: 15px !important;
}
.news_part h5{
    font-weight: 700 !important;
    font-size: 17px !important;
    margin-top: 7px !important;
    cursor: pointer;
}
.short_news{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.single_news_details p{
    font-weight: 700 !important;
    margin: 7px 0 !important;
}
.single_news_details span{
    font-size: 15px !important;
}
.short_news > div{
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
    flex-direction: row !important;
}
.new_explore_services{
    display: flex !important;
}
.new_countrycode_field{
    height: 44px !important;
}

.new_countrycode_field > div{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
}
.new_countrycode_field input{
    height: 100%;
    border: 1px solid rgba(0,0,0,0.2) !important;
    border-radius: 4px !important;
}
.new_countrycode_err input{
    height: 100%;
    border: 1px solid rgba(255, 0, 0, 1) !important;
    border-radius: 4px !important;
}
.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: var(--language-secondary-color) !important;
    /* border-width: 2px !important; */
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
    overflow: auto !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}
@media only screen and (max-width: 600px) {
    .card_info button{
        font-size: 15px !important;
        padding-right: 20px !important;
    }
    .card_info p{
        font-size: 17px !important;
    }
    .nri_card{
        padding-top: 15px !important;
    }
    .we_provides_details{
        grid-template-columns: auto !important;
    }
    .all_counts{
        justify-content: space-around !important;
    }
    .news_art{
        top: 50px;
        left: 50px;
        color: #fff !important;
        font-size: 26px !important;
        font-weight: 600 !important;
    }
    .short_news > div{
        align-items: baseline !important;
        gap: 15px !important;
        flex-direction: column !important;
    }
    .new_explore_services{
        display: grid !important;
        grid-template-columns: auto auto !important;
    }
    .connect_us{
        font-size: 27px !important;
    }
}