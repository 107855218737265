.new_countrycode_field{
    height: 44px !important;
}
.new_countrycode_field > div{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
}
.new_countrycode_field input{
    height: 100%;
    border: 1px solid rgba(0,0,0,0.2) !important;
    border-radius: 4px !important;
}
.new_countrycode_err input{
    height: 100%;
    border: 1px solid rgba(255, 0, 0, 1) !important;
    border-radius: 4px !important;
}
.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: var(--language-secondary-color) !important;
    /* border-width: 2px !important; */
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
    overflow: auto !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}