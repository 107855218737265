.guestHeaderParentDiv {
    position: sticky;
}

.guestBodyParentDiv {
    position: sticky;
    height: calc(100vh - 60px);
}

.guestBodyParentDiv > div:first-child{
    height: 100%;
}

.guestHeaderMainDiv {
    display: flex;
    align-items: center;
    padding: 0 10%;
    height: 60px !important;
    justify-content: space-between;
}

.guestHeaderLogo {
    height: 45px;
}
.property_layout{
    background-color: var(--default-background-color) !important;
    min-height: 90.3vh !important;
    padding: 70px 0;
}
.header{
    color: #fff !important;
    font-size: 32px !important;
    font-weight: 700 !important;
}