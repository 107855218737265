.location-select fieldset {
  border: none !important;
}
.location-select .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  color: var(--text-color-1) !important;
}
.homepage-tabs {
  background-color: rgba(0, 0, 0, 0.8) !important;
  top: -67px !important;
  left: 0;
  position: absolute !important;
  padding: 0 12px 12px 12px !important;
}
.homepage-tabs .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #fff !important;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.homepage-tabs .MuiButtonBase-root.MuiTab-root {
  color: #b3aaaa !important;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0.7px !important;
}
.homepage-tabs .MuiTabs-indicator {
  background-color: #fff !important;
}
/* .new-select::after{
    content: "";
    width: 2px !important;
    height: 75%;
    position: absolute;
    right: -15px;
    top: 8px;
    background-color: rgba(0, 0, 0, 0.5);
} */
.search-field-loc .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
  background-color: transparent !important;
  border: none !important;
  width: 300px !important;
}
.search-field-loc fieldset {
  border: 0 !important;
}
.search-field-loc
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.search-field .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
  background-color: transparent !important;
  border: none !important;
  width: 349px !important;
}

.search-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.home-search-field .MuiInputBase-root.MuiOutlinedInput-root {
  border-left: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
}

.search-field fieldset {
  border: 0 !important;
}
.mobile-search-field .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
  background-color: transparent !important;
  border: none !important;
  width: 200px !important;
}
.mobile-search-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.mobile-search-field fieldset {
  border: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}
.react-multiple-carousel__arrow--right {
  right: 0 !important;
}
.carousel-wrapper {
  padding: 10px 0;
}

.location-tabs .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #fff !important;
  background-color: var(--border-colour) !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
}
.location-tabs .MuiButtonBase-root.MuiTab-root {
  color: #292929 !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: 0.7px !important;
}
.location-tabs .MuiTabs-indicator {
  display: none !important;
}
.manage-listing
  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
  padding: 8px 32px 8px 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.manage-listing
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #64898a !important;
  border-width: 1px !important;
}
.price-slider .MuiSlider-root {
  color: var(--text-color-1) !important;
}
.filter-accordian {
  overflow-y: scroll;
  height: auto;
  max-height: 342px;
  overflow-x: hidden;
}
.filter-accordian .MuiPaper-root.MuiAccordion-root {
  box-shadow: none !important;
}
.filter-accordian .MuiPaper-root.MuiAccordion-root::before {
  background-color: #fff !important;
}
/* .myprofile-popover > div{
  width: 360px !important;
  height: 88vh !important;
  top: 55px !important;
}
.myprofile-popover > div{
  width: 360px !important;
  height: 88vh !important;
  top: 55px !important;
} */
.myprofile-popover hr {
  margin: 0 !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
}
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  background-color: var(--text-color-1) !important;
  color: #fff !important;
}
.popper-accordian .MuiPaper-root.MuiAccordion-root {
  box-shadow: none !important;
}
.popper-accordian .MuiPaper-root.MuiAccordion-root::before {
  background-color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .search-field .MuiInputBase-input.MuiOutlinedInput-input {
    width: auto !important;
  }
  .home-search-field .MuiInputBase-root.MuiOutlinedInput-root {
    border-left: 0px solid rgba(0, 0, 0, 0.5);
    border-radius: 0;
  }
}

@media only screen and (min-width: 1700px) {
  .filter-accordian {
    max-height: unset !important;
  }
}
