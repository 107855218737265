.stepper{
    margin-top: 32px !important;
}
.stepper .MuiStepLabel-label{
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}
.stepper svg{
    fill: #BBE15B !important;
}
.select-field p{
    margin: 0 !important;
}
.select-field .new-check-box{
    display: none !important;
}
.contact-close {
    /* position: absolute;
    top: 11px;
    right: 11px; */
    font-size: 28px;
    cursor: pointer;
}
.add-more-items .MuiPaper-root.MuiDialog-paper{
    width: 350px !important;
    height: 800px !important;
    max-width: 850px !important;
    /* padding: 24px; */
    position: relative;
}
.add-more-items .main-title{
    background-color: var(--default-background-color) !important;
    color: #fff !important;
    padding: 8px 24px !important;
}
.add-more-items .sub-title{
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}
.add-more-items .checkbox{
    margin-top: -8px !important;
    color: #374957 !important;
}
.all-listings{
    margin-top: 20px !important;
}
.all-listings .MuiButtonBase-root.MuiTab-root.Mui-selected{
    color: var(--text-color-1) !important;
    text-transform: capitalize;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.all-listings .MuiButtonBase-root.MuiTab-root{
    color: var(--secondary-text-color) !important;
    text-transform: capitalize;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.all-listings .MuiTabs-indicator{
    background-color: var(--text-color-1) !important;
}
.single-property-tabs{
    background-color: #fff !important;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding-top: 8px !important;
}
.single-property-tabs .MuiButtonBase-root.MuiTab-root{
    color: var(--text-color-2) !important;
    text-transform: capitalize;
    font-size: 18px !important;
    font-weight: 500 !important;
}
.single-property-tabs .MuiTabs-indicator{
    background-color: var(--text-color-2) !important;
}
.sorting-popover .MuiPaper-root.MuiPopover-paper{
    min-width: 310px !important;
    width: 310px !important;
}
.manage-listing .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select{
    padding: 8px 32px 8px 15px !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.manage-listing .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
border-color: #64898A !important;
border-width: 1px !important;
}
.myprofile-popover hr{
    margin: 0 !important;
}
.inner-select .MuiOutlinedInput-notchedOutline{
    border-color: transparent !important;
}
.inner-select .MuiInputBase-input.MuiOutlinedInput-input{
    min-width: 75px !important;
    width: auto !important;
    border-left: 1px solid rgba(0, 0, 0, 0.2) ;
    border-radius: 0 !important;
}
.delete-part .MuiButtonBase-root.MuiRadio-root.Mui-checked{
    color: #BBE15B !important;
}
.delete-part .MuiButtonBase-root.MuiRadio-root{
    color: #fff !important;
}
.expected .MuiSvgIcon-root.MuiSelect-icon{
    display: none !important;
}