.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a{
  text-decoration: none !important;
}
.FocusedClass>.css-953pxc-MuiInputBase-root-MuiInput-root::after {
  border-bottom: 2px solid green;
  /* Example: a solid green border with a width of 2px */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.d-flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex{
  display: flex;
  align-items: center;
}

.d-flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-row-reverse{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100% !important;
}

.optional{
  font-size: 16px;
  margin-left: 5px;
  color: rgba(0,0,0,0.5);
}

.my-field .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 10px !important;
  background-color: #f8f8f8 !important;
}

.my-field .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--language-secondary-color) !important;
}
.my-field .MuiInputBase-root.MuiOutlinedInput-root{
  padding: 0 !important;
}
.my-field-two .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 10px !important;
}

.my-field-two .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--language-secondary-color) !important;
}
.my-field-two .MuiInputBase-root.MuiOutlinedInput-root{
  padding: 0 !important;
}
.my-field-three .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 9px !important;
}

.my-field-three .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--language-secondary-color) !important;
}
.my-field-three .MuiInputBase-root.MuiOutlinedInput-root{
  padding: 0 !important;
}
.MuiButtonBase-root.MuiButton-root.Mui-disabled{
  background-color: #3d6c6d73 !important;
}
.country-code .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 10px !important;
  min-width: 69px !important;
  width: auto !important;
  border-radius: 0 !important;
}
.country-code fieldset{
  border: none !important;
}
ul:has(.all-codes){
  height: 350px !important;
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 7px;
  height: 3px !important;
}
::-webkit-scrollbar-track { 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #969696 !important; 
  border-radius: 10px;
}
.custom-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.3); 
}

.custom-placeholder:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.custom-placeholder::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --default-background-color: #3D6C6D;
  --language-secondary-color: #C3D1D2;
  --text-color-1: #2E5152;
  --text-color-2: #222222;
  --text-color-3: #F8F8F8;
  --white-color-text: #FFFFFF;
  --secondary-text-color: #636363;
  --border-colour: #275253;
  --disabled-btn-color: #E6E6E6;
  --disabled-btn-text-color: #9A9A9A;
  --default-bg-color: #093F40;
  --text-color-1: #2E5152;
  --white-color: #ffffff;
  --focused-inputborder: #A4D037;
  --error-color: #F44336;
  --selected-menu-bg: #186769;
  --active-border: #BBE15B;
  --header-sub-text: #001540;
  --default-child-bg-color: #F0F2F4;
  --default-border-color: #DFDFDF;
  --default-btn-text-color: #878790;
  --default-header-row-color: #CFF8F2;
  --default-table-header-color: #F0F2F4;
}