@import url('https://fonts.googleapis.com/css?family=Sen&display=swap');

 .dropdown{
   /* padding: 20px 40px; */
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   position: relative;
   font-size: 18px;
   perspective: 1000px;
   z-index: 100;
   }
   .dropdown:hover{
    /* background: #2980b9 ; */
    cursor: pointer;
   }
   .dropdown:hover .dropdown_menu .dp-list{
    display: block;
   }

 .dropdown_menu{
   position: absolute;
   top: 100%;
   left: 0;
   width: 100%;
   perspective: 1000px;
   z-index: -1;
 }
 .dp-list{
    display: none;
    color: var(--text-color-1) !important;
    background-color: #fff !important;
    padding: 10px 20px;
    font-size: 16px;
    opacity: 0;
      
   }

   .dp-list:hover{
    background-color: var(--text-color-1) !important;
    color: #fff !important;
   }



.dropdown:hover .dropdown_menu--animated{
    display: block;
    padding: 12px 0 !important;
    width: 270px !important;
}
.dropdown_menu--animated{
    display: none}
    .dp-list{
      display: block;
      opacity: 1;
}

.arrow-rotate{
    transform: rotate(181deg) !important;
}



.dropdown_menu-8{
    animation: downOut 300ms ease-in-out forwards;
    transform-origin: center center;
}

@keyframes downOut {
     0% {
        transform: translateZ(200px) transLateY(40px)
    }
    80% {
        transform: translateZ(-10px) transLateY(0px)
    }
    100% {
        transform: translateZ(0px) transLateY(0px)
    }
}








@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  
  80% {
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}



.one{
  position: relative;
}



  .arr {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
  }
  .dp-list:hover .arr {
    opacity: 1;
    right: -2rem;
  }
