body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SFPRO";
  font-style: normal;
  font-weight: normal;
  src: local("SFPRO"),
    url("./Assets/Fonts/SFPRODISPLAYREGULAR.OTF") format("woff");
  font-family: "Source Sans Pro" !important;
}

@font-face {
  font-family: "Abel";
  font-style: normal;
  font-weight: normal;
  src: local("Abel"),
    url("./Assets/Fonts/FontsFree-Net-Abel-Regular.ttf") format("woff");
  font-family: "Abel" !important;
}

@font-face {
  font-family: "ABeeZee";
  font-style: normal;
  font-weight: normal;
  src: local("ABeeZee"),
    url("./Assets/Fonts/ABeeZee-Regular.ttf") format("woff");
  font-family: "ABeeZee" !important;
}

@font-face {
  font-family: "Tiro Devanagari Hindi";
  font-style: normal;
  font-weight: normal;
  src: local("Tiro Devanagari Hindi"),
    url("./Assets/Fonts/Tiro-Devanagari-Hindi-Regular.ttf") format("woff");
  font-family: "Tiro Devanagari Hindi" !important;
}

* {
  font-family: "SFPRO" !important;
}