.delete_popup .MuiPaper-root.MuiDialog-paper {
  width: 500px !important;
  height: 270px !important;
  max-width: 850px !important;
  padding: 24px;
  position: relative;
}
.delete_popup .alert-icon {
  font-size: 60px;
  color: #f33d3d;
  margin-bottom: 15px;
}
.delete_popup .content {
  font-size: 23px !important;
}
.delete_popup .content small {
  font-size: 15px;
}
.delete_popup .no {
  color: var(--text-color-2) !important;
  background-color: #fff !important;
  border: 1px solid var(--border-colour) !important;
  text-transform: capitalize !important;
  padding: 2px 14px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.delete_popup .yes {
  color: #fff !important;
  background-color: var(--border-colour) !important;
  border: 1px solid var(--border-colour) !important;
  text-transform: capitalize !important;
  padding: 2px 14px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.image-slider .MuiPaper-root.MuiDialog-paper {
  width: 1200px !important;
  max-width: 1200px !important;
  height: 600px !important;
  max-width: 850px !important;
  /* padding: 24px !important; */
  position: relative;
  overflow: hidden !important;
}
.image-slider .carousel-wrapper {
  height: 100% !important;
}
.image-slider .slider-header {
  text-align: center;
  font-size: 22px !important;
  font-weight: 600 !important;
}
.single-property-images .MuiButtonBase-root.MuiTab-root {
  color: var(--text-color-2) !important;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.single-property-images .MuiTabs-indicator {
  background-color: var(--text-color-2) !important;
}
.add-services .MuiPaper-root.MuiDialog-paper {
  width: 55% !important;
  max-width: 100% !important;
  padding: 24px;
  padding-top: 10px;
  position: relative;
}
.add-services-cancel {
  background-color: #fff !important;
  color: var(--default-background-color) !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important;
  font-size: 17px !important;
  padding: 5px 12px !important;
}
.add-services-submit {
  background-color: var(--default-background-color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important;
  font-size: 17px !important;
  padding: 5px 12px !important;
}
.service-pop-close{
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
}
.popup-header{
  /* background-color: var(--default-background-color) !important; */
  /* color: #fff !important; */
  padding: 8px 24px !important;
  margin-top: 10px !important;
}
.popup-header svg{
  cursor:  pointer !important;
}
.webcam-pop .MuiPaper-root.MuiDialog-paper {
  width: auto !important;
  min-width: 35% !important;
  max-width: 100% !important;
  /* padding: 24px; */
  position: relative;
  overflow:  hidden !important;
}
.contact-pop .MuiPaper-root.MuiDialog-paper {
  width: 440px !important;
  position: relative;
}
.contact-header svg {
  width: 22px !important;
  height: 22px !important;
}
.contact-header {
  display: flex;
  align-items: center;
  font-size: 26px !important;
  gap: 5px;
}
.contact-close {
  /* position: absolute;
  top: 11px;
  right: 11px; */
  font-size: 28px;
  cursor: pointer !important;
}
.capture-btn {
  background-color: var(--default-background-color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important;
  font-size: 13px !important;
  padding: 5px 12px !important;
}
.webcam {
  width: 70% !important;
  height: 100%;
  margin: auto;
}
.retake-btn {
  background-color: #fff !important;
  color: var(--default-background-color) !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important;
  font-size: 13px !important;
  padding: 5px 12px !important;
}
.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: var(--text-color-1);
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}
.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}
.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}
.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

.notification-pop .MuiPaper-root.MuiDialog-paper{
    width: auto !important;
    max-width: 70% !important;
    min-width: 45% !important;
    /* max-width: 100% !important; */
    /* padding: 24px; */
    position: relative;
}
.notification-box{
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 30px;
    display: flex;
    align-items: start;
    gap: 20px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
}
.notification-active{
  background-color: #3d6c6d38  !important;
}
.notification-time{
    position: absolute;
    bottom: 7px;
    right: 35px;
    color: black;
    font-weight: 600 !important;
}
.hover-onclose{
  position: absolute;
  top: 13px;
  right: 16px;
  font-size: 21px !important;
  color: #db5050 !important;
  visibility: hidden;
  
}
.notification-box:hover .hover-onclose{
  visibility: visible;
  transition: visibility 0.2s;
}
.google-map .MuiPaper-root.MuiDialog-paper {
  width: 55% !important;
  max-width: 100% !important;
  /* padding: 24px; */
  position: relative;
}
.profile-detail-pop .MuiPaper-root.MuiDialog-paper {
  width: 440px !important;
  position: relative;
}
.edit-image{
      width: 25% !important;
      margin: auto !important;
      height: 106px !important;
      border-radius: 50% !important;
      position: relative;
}
.edit-image-icon{
  position: absolute !important;
  bottom: 8px;
  right: 14px;
  background-color: #fff !important;
}
.edit-image > svg{
  width: 100%;
  height: 100%;
}
.post-enquiry .MuiPaper-root.MuiDialog-paper {
  width: 45% !important;
}
.enquiry-title{
  font-size: 17px !important;
  font-weight: 500 !important;
  margin: 6px 0 !important;
}
.language-pop .MuiPaper-root.MuiDialog-paper {
  width: 35% !important;
  padding: 16px !important;
}
.selected-language{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected-language p{
  color: var(--border-colour) !important;
  font-weight: 600 !important;
}
.selected-language .MuiButtonBase-root.MuiRadio-root.Mui-checked{
  color: var(--border-colour) !important;
}
.feedback-pop .MuiPaper-root.MuiDialog-paper {
  min-width: 35% !important;
  width: auto !important;
  padding: 16px !important;
}
.rating-btns{
  display: grid !important;
  grid-template-columns: auto auto !important;
  column-gap: 20px !important;
  padding: 16px 16px 0 16px !important;
}
.rating-btns button:first-child{
  background-color: #fff !important;
  color: var(--default-background-color) !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important
}
.rating-btns button:last-child{
  background-color:  var(--default-background-color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important
}
.loc-search-field .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
  background-color: transparent !important;
  /* border: none !important; */
  width: 349px !important;
}
.loc-search-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.loc-search-field fieldset {
  /* border: 0 !important; */
}
.feedback-type{
  color: #d32f2f;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.feedback-type-list p{
  margin: 0 !important;
}
.feedback-success{
  color: #046904;
  background-color: #00800047;
  padding: 10px;
  margin-top: 10px !important;
  border-radius: 4px;
}
.feedback-failure{
  color: red;
  background-color: #ff000030;
  padding: 10px;
  margin-top: 10px !important;
  border-radius: 4px;
}
.name-phone-wrapper{
  display: grid !important;
  grid-template-columns: auto auto !important;
  gap: 20px !important;
}
.guest-pop .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  padding: 16px !important;
  min-height: 300px !important;
  height: auto !important;
}
.guest-user-back{
  background-color: #fff !important;
  color: var(--default-background-color) !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important;
  font-size: 17px !important;
  padding: 5px 12px !important;
}
.guest-btns{
  display: grid !important;
  grid-template-columns: auto auto !important;
  gap: 15px !important;
  margin-bottom: 15px;
}
.block-pop .MuiPaper-root.MuiDialog-paper {
  width: 550px !important;
  padding: 16px 16px 30px 16px !important;
  min-height: 300px !important;
  height: auto !important;
}
.block-pop .alert-icon {
  font-size: 60px;
  color: #f33d3d;
  margin-bottom: 15px;
}
.add-fields-ul{
  list-style-type: none !important;
  margin-top: 18px !important;
}
.add-more-btn {
  background-color: var(--default-background-color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-background-color) !important;
  font-size: 13px !important;
  padding: 5px 12px !important;
  border-radius: 15px !important;
}
.addfield-pop .MuiPaper-root.MuiDialog-paper {
  min-width: 23% !important;
  width: 23% !important;
  padding: 16px !important;
}