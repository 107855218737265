.download {
  color: var(--text-color-1) !important;
  background-color: #fff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}
.post_property {
  font-weight: 500 !important;
  font-size: 17px !important;
  background-color: #c6ee90 !important;
  color: var(--text-color-1) !important;
  padding: 5px 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 50px !important;
}
.post_property b {
  padding-left: 10px;
}
.sub_menus {
  justify-content: center;
  gap: 25px;
  background-color: var(--text-color-1) !important;
  padding: 12px 0 !important;
}
.sub_menus p {
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  cursor: pointer;
}
.tabs {
  position: absolute;
  bottom: 0;
  left: 0px;
  /* display: flex;
    justify-content: center; */
  text-align: center;
  width: 100%;
}
.search_part {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px;
  /* width: 45%; */
  margin: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.navbar_search_part {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px;
  width: 48%;
  margin: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.mobile_search_part {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px;
  width: 70%;
  /* margin: auto; */
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.search_btn {
  background-color: var(--text-color-1) !important ;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  padding: 5px 30px !important;
}
.search_btn_small {
  background-color: var(--text-color-1) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 2px 20px !important;
  /* border: 1px solid var(--text-color-1) !important; */
}
.mobile_search_btn_small {
  background-color: var(--text-color-1) !important;
  /* border: 1px solid var(--text-color-1) !important; */
  font-size: 39px !important;
  padding: 5px;
  border-radius: 4px;
  color: white;
}
.clear_btn_small {
  background-color: #fff !important;
  color: var(--text-color-1) !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 2px 20px !important;
  /* border: 1px solid var(--text-color-1) !important; */
  margin-left: 5px !important;
}
.mobile_clear_btn_small {
  background-color: #fff !important;
  color: var(--text-color-1) !important;
  font-size: 39px !important;
  padding: 5px !important;
  /* border: 1px solid var(--text-color-1) !important; */
  margin-left: 5px !important;
}
.box_title {
  color: var(--text-color-2) !important;
  font-weight: 500 !important;
  font-size: 36px !important;
}
.box_sub_title {
  color: var(--text-color-2) !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}
.box_title_two {
  color: var(--text-color-2) !important;
  font-weight: 600 !important;
  font-size: 32px !important;
}
.view_all {
  color: var(--text-color-1) !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  cursor: pointer;
}
.slider_heart_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white !important;
  padding: 6px !important;
  border-radius: 50% !important;
  cursor: pointer;
}
.single_property_view .active_heart svg {
  fill: #374957 !important;
}
.single_property_view {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important;
  width: 90% !important;
  border-radius: 6px !important;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.single_property_view_img {
  height: 230px;
  overflow: hidden;
  cursor: pointer;
}
.single_property_view_img img {
  transform: scale(1);
  transition: 0.2s;
}
.single_property_view_img img:hover {
  transform: scale(1.1);
}
.single_property_title {
  color: var(--text-color-2) !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}
.single_property_location {
  color: var(--text-color-2) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.single_property_highlights {
  color: var(--text-color-1) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin: 10px 0 !important;
}
.single_property_feet {
  color: var(--text-color-2) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.single_property_price {
  color: var(--text-color-2) !important;
  font-weight: 600 !important;
  font-size: 23px !important;
}
.single_property_price span {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.contact_btns {
  color: var(--text-color-1) !important;
  border: 1px solid var(--text-color-1) !important;
  text-transform: capitalize !important;
  padding: 6px 15px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  background-color: #fff !important;
}
.contact_btns svg {
  margin-right: 8px !important;
}
.find_property {
  background-color: #fff !important;
  border-radius: 8px;
  color: var(--text-color-1) !important;
  display: inline-block;
  font-size: 20px !important;
  font-weight: 600 !important;
  padding: 7px 36px;
  margin-top: 33px !important;
  cursor: pointer;
}
.services {
  padding: 14px !important;
  padding-top: 24px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #c6ee90 !important;
  border-radius: 10px;
  /* height: 100%; */
  height: 190px;
  min-width: 143px;
  cursor: pointer;
  margin-top: 12px !important;
}
.services p {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 22px !important;
  text-align: center;
}
.services img {
  width: 75% !important;
}
.services_wrapper {
  /* display: grid; */
  /* grid-template-columns: auto auto auto auto auto auto auto; */
  gap: 15px;
  display: flex;
  align-items: center;
  overflow: scroll;
  margin-top: 12px !important;
  scroll-behavior: smooth !important;
  position: relative;
  width: 97%;
  margin: auto;
  z-index: -1;
}
.services_wrapper::-webkit-scrollbar {
  width: 0px !important;
  display: none !important;
}
.services_next {
  position: absolute;
  right: -3px;
  top: 190px;
  font-size: 36px;
  padding: 5px;
  /* z-index: 1; */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
}
.services_prev {
  position: absolute;
  left: -3px;
  top: 190px;
  font-size: 36px;
  padding: 5px;
  /* z-index: 1; */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
}
.scroll_top {
  position: fixed;
  bottom: 25px;
  right: 25px;
}
.callnow_btns {
  color: #fff !important;
  text-transform: capitalize !important;
  padding: 8px 15px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  background-color: var(--border-colour) !important;
  text-transform: capitalize !important;
  width: 250px !important;
}
.callnow_btns svg {
  margin-right: 8px !important;
}
.post_whatsapp {
  display: flex !important;
  align-items: center;
  gap: 10px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: var(--text-color-2) !important;
  background-color: #fff !important;
  text-transform: capitalize !important;
  width: 250px !important;
}
.inner_land {
  position: absolute;
  bottom: -45px;
  left: 40px;
  background-color: #fff !important;
  padding: 12px;
  width: 87%;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.land_title {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: var(--text-color-2) !important;
}
.land_sub_title {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--text-color-2) !important;
}
.land_contact_btn {
  color: var(--text-color-1) !important;
  border: 1px solid var(--text-color-1) !important;
  text-transform: capitalize !important;
  padding: 6px 19px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  background-color: #fff !important;
}
.land_contact_btn svg {
  margin-right: 8px !important;
}
.no_buyers {
  background-color: var(--default-background-color) !important;
  height: 250px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 22px;
  padding-left: 26px;
  position: relative;
  border-radius: 10px;
}
.no_buyers p {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: #fff !important;
}
.no_buyers span {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #fff !important;
}
.no_buyers button {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--text-color-1) !important;
  border-radius: 25px !important;
  background-color: #bbe15b !important;
  text-transform: capitalize !important;
}
.no_buyer_icon {
  position: absolute;
  right: 30px;
  top: 40px;
}
.phone_in_hand_icon {
  position: absolute;
  right: 20px;
  bottom: 0px;
}
.free_property {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: var(--default-background-color) !important;
  border-radius: 18px !important;
  padding: 0 16px !important;
  margin-left: 20px !important;
}
.footer_part span {
  color: #ffffffba !important;
  display: block !important;
  margin: 14px 0 !important;
  letter-spacing: 0.3px;
  cursor: pointer;
}
.footer_part span:hover {
  color: #fff !important;
}
.footer_icons svg {
  margin-right: 10px !important;
  cursor: pointer;
}
.my_list_title {
  font-size: 32px !important;
  font-weight: 600 !important;
}
.property_res {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
}
.plan_card {
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  background-color: #fff !important ;
}
.plan_card_active {
  border: 1px solid #8ca944 !important;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  background-color: #c6ee90 !important;
  /* transform: scale(1.03) !important; */
}
.hover_plans {
  transition: transform 0.2s;
  min-width: 183px;
}
.hover_plans:hover {
  transform: translate(0px, -10px);
}
.single_plan_info {
  display: flex !important;
  align-items: center !important;
  gap: 11px !important;
  margin: 18px 0 !important;
}
.plan_subscribe {
  background-color: #8ca944 !important ;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  padding: 5px 30px !important;
}
.search_row_one {
  border-radius: 8px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  padding: 16px;
}
.result_counts {
  font-weight: 600 !important;
  font-size: 19px !important;
  margin-top: 29px !important;
}
.heart_icon {
  padding: 5px;
  border: 1px solid #275253 !important;
  border-radius: 8px;
  cursor: pointer;
}
.active_heart svg {
  fill: #374957 !important;
}
.search_res_btn {
  color: var(--text-color-1) !important;
  border: 1px solid var(--text-color-1) !important;
  text-transform: capitalize !important;
  padding: 6px 17px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  background-color: #fff !important;
}
.search_res_btn svg {
  margin-right: 8px !important;
  width: 19px !important;
}
.mobile_filter {
  font-size: 20px !important;
  background-color: #275253 !important;
  color: #fff !important;
  cursor: pointer;
  padding: 3px 12px;
  border-radius: 4px;
}
.filter_part {
  border-radius: 8px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 80px;
}
.filter_part hr {
  margin: 0 !important;
}
.clear_btn {
  color: #515151 !important ;
  background-color: #fff !important;
  text-transform: capitalize !important;
}
.nav_menubar svg {
  font-size: 23px !important;
  cursor: pointer !important;
}
.no_data {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.no_data p {
  color: var(--text-color-2) !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}
.no_data span {
  color: var(--text-color-2) !important;
  font-size: 23px !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.notification_count {
  position: absolute;
  right: -9px;
  top: -8px;
  background-color: #eb4b4b;
  border-radius: 50%;
  padding: 0 6px;
  color: white;
  font-size: 14px;
}
.text_danger_glow {
  color: #ffffff;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00,
    0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}
.blink {
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.popper_profile_details {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  border-radius: 6px;
}
.popper_count {
  background-color: var(--default-bg-color) !important;
  border-radius: 8px !important;
  display: grid;
  grid-template-columns: auto auto auto;
  height: 60px !important;
  margin-top: 35px !important;
  padding: 10px;
}
.popper_count div {
  color: #fff !important;
  position: relative;
}
.popper_count span {
  position: absolute;
  top: -23px;
  /* left: 16px; */
  background-color: #bbe15b !important;
  color: black !important;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 14px !important;
}
.popper_fields {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 9px 0 !important;
  cursor: pointer;
}
.popper_fields span {
  font-size: 16px;
}
.sub_popper_fields {
  display: flex;
  align-items: center;
  gap: 12px;
}
.sub_popper_fields span {
  font-size: 18px;
}
.hover_tabs:hover {
  background-color: red !important;
}

.animi_btns_two {
  width: 95px !important;
}
.animi_btns {
  width: 131px;
  height: 56px;
  overflow: hidden;
  border: none;
  color: #fff;
  background: none;
  position: relative;
  padding-bottom: 2em;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
}

.animi_btns > div,
.animi_btns > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.animi_btns:before {
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: bottom right;
  background: currentColor;
  transition: transform 0.25s ease-out;
}

.animi_btns:hover:before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.animi_btns .clone > *,
.animi_btns .text > * {
  opacity: 1;
  font-size: 1.3rem;
  transition: 0.2s;
  margin-left: 4px;
}

.animi_btns .clone > * {
  transform: translateY(60px);
}

.animi_btns:hover .clone > * {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.animi_btns:hover .text > * {
  opacity: 1;
  transform: translateY(-60px);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.animi_btns:hover .clone > :nth-child(1) {
  transition-delay: 0.15s;
}

.animi_btns:hover .clone > :nth-child(2) {
  transition-delay: 0.2s;
}

.animi_btns:hover .clone > :nth-child(3) {
  transition-delay: 0.25s;
}

.animi_btns:hover .clone > :nth-child(4) {
  transition-delay: 0.3s;
}
/* icon style and hover */
.animi_btns svg {
  width: 20px;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-50deg);
  transition: 0.2s ease-out;
}

.animi_btns:hover svg {
  transform: translateY(-50%) rotate(-90deg);
}

.ad_part {
  border-radius: 8px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 80px;
  height: 90vh;
  overflow: scroll;
}
.ad_part::-webkit-scrollbar {
  width: 0px;
}
.single_breadcrums_active {
  padding: 0 5px;
  cursor: pointer;
}
.single_breadcrums_inactive {
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
}
.multi_select label {
  top: -6px !important;
  color: rgba(0, 0, 0, 0.3) !important;
}
.multi_select_val label {
  display: none !important;
}
.post_property {
  position: relative;
  overflow: hidden;
}
.marker_view_more {
  color: blue !important;
  text-decoration: underline !important;
  margin-top: 8px !important;
  cursor: pointer !important;
}

.post_property:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.post_property::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.filter_preview {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}
.search_initail_loc {
  position: absolute;
  background-color: #fff;
  width: 400px !important;
  height: 200px !important;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important;
  padding: 16px !important;
  z-index: 1 !important;
  top: 47px;
  border-radius: 4px !important;
  left: -170px;
}
.search_initail_loc hr {
  margin: 0 !important;
}
.search_initail_loc h3 {
  margin-bottom: 8px !important;
}
.search_initail_loc svg {
  width: 20px;
}
.loc_search_close {
  position: absolute;
  top: 9px;
  font-size: 22px;
  right: 13px;
  cursor: pointer;
}
.our_plans {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 19px;
  overflow-x: scroll;
}

@media only screen and (max-width: 600px) {
  .search_part {
    /* width: 95%; */
  }
  .box_title {
    font-size: 21px !important;
  }
  .box_sub_title {
    font-size: 15px !important;
  }
  .view_all {
    font-size: 17px !important;
  }
  .inner_land {
    left: 0;
    width: 100%;
  }
  .no_buyer_icon {
    display: none;
  }
  .my_list_title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .search_btn {
    font-size: 14px !important;
  }
  .box_title_two {
    font-size: 24px !important;
  }
  .no_buyers p {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #fff !important;
  }
  .no_buyers span {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #fff !important;
  }
  .no_buyers button {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--text-color-1) !important;
    border-radius: 25px !important;
    background-color: #bbe15b !important;
    text-transform: capitalize !important;
  }
  .no_buyers {
    height: 190px;
  }
  .phone_in_hand_icon {
    width: 78px;
  }
}
