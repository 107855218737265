.languageScreenMainDiv {
    background-color: var(--default-background-color);
    height: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.languageCardMainDiv {
    padding: 20px !important;
    width: 55% !important;
    margin: 0 auto !important;
}

.languageCardSubDiv {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    row-gap: 40px;
    width: 60%;
    margin: 0 auto;
}

.languageCardMainHeading {
    font-family: "ABeeZee" !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: center;
    color: var(--text-color-2) !important;
    -webkit-text-stroke: 1px black !important;
}

.languageCardSubHeading {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: center;
    color: var(--text-color-2) !important;
    -webkit-text-stroke: 1px black !important;
    text-shadow: 0px 4px 4px #00000040 !important;
}

.languageSubCardSubHeading {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--text-color-1);
    position: absolute;
    top: 10px;
    left: 10px;
}

.languageSubCardMainHeading {
    font-family: "Tiro Devanagari Hindi" !important;
    font-size: 58px !important;
    font-weight: 400 !important;
    line-height: 77px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--text-color-1) !important;
    margin-top: 20px !important;
}

.LanguagesContainerDiv {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 4%;
    width: 100%;
}

.languagesListCard {
    width: 100%;
    height: 130px;
    background: var(--text-color-3) !important;
    border: 1px solid;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px !important;
    cursor: pointer !important;
}

.continueBtn {
    height: 48px !important;
    width: 100%;
    background: var(--text-color-1) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em;
    text-align: center;
    border: 10px !important;
    text-transform: none !important;
}

.checkedIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.selectedLanguageCard {
    background: var(--language-secondary-color) !important;
}

.loginScreenMainDiv {
    background-color: var(--default-background-color);
    height: 100%;
}

.loginScreenMainDiv {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 100%;
}

.loginPageImage {
    height: calc(100vh - 60px);
    width: 100%;
}

.loginDefaultHeaderText {
    font-size: 36px !important;
    font-weight: 600 !important;
    line-height: 46px !important;
    letter-spacing: 0em;
    text-align: left;
}

.closeButton {
    min-width: unset !important;
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.defaultPageTextContainer {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.defaultPageHeaderText {
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 46px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--white-color-text);
}

.defaultPageList {
    list-style-type: disc !important;
    margin-top: 20px !important;
    margin-bottom: 0 !important;
}

.defaultListItem {
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 40px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color-text);
}

.loginDefaultLayoutChildContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.registerCard {
    height: 80%;
    width: 60%;
    padding: 25px !important;
    position: relative;
}

.registerHeader {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--text-color-1);
}

.SignUpForm {
    margin-top: 20px !important;
}

.formLable {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--text-color-2);
}


.InputAdornmentText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--text-color-2);
}

.FocusedClass>div::after {
    border-color: var(--text-color-1);
}

.otpInputContainer {
    /* column-gap: 5px !important; */
    justify-content: space-between;
}

.otpInput {
    height: 48px;
    width: 48px !important;
    border-radius: 5px !important;
    border-width: 0.5px !important;
    border:1px solid var(--language-secondary-color) !important;
}

.SecondsText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary-text-color);
}

.OtpFormContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 35px 0 20px;
}

.resendOtpBtn {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--text-color-1) !important;
    cursor: pointer !important;
    text-transform: none !important;
}

.otpResendDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.whatsAppBtn {
    display: flex !important;
    align-items: center;
    gap: 10px !important;
    height: 45px;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left;
    text-transform: none !important;
    color: var(--text-color-1) !important;
    border: 1px solid var(--border-colour) !important;                          
}

.LockTermsText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--border-colour) !important;
    display: flex;
    align-items: center;
    column-gap: 10px !important;
}

.disabledBtn {
    background-color: var(--disabled-btn-color) !important;
    color: var(--disabled-btn-text-color) !important;
}

.NoteText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
}

.NoteText span {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--text-color-1);
    cursor: pointer;
}

.successModalMainHeading {
    font-size: 25px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: center;
    width: 60%;
}

.successModalSubHeading {
    font-size: 19px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: center;
    width: 60%;
}

.confirmationModalBtnSection {
    display: flex;
    column-gap: 20px !important;
}

.cancelBtn {
    color: var(--text-color-2) !important;
    background-color: #fff !important;
    border:1px solid var(--border-colour) !important;
    text-transform: capitalize !important;
    padding: 2px 14px !important;
    font-size: 17px !important;
    font-weight: 500 !important;
}

.confirmBtn {
    color: #fff !important;
    background-color: var(--border-colour) !important;
    border:1px solid var(--border-colour) !important;
    text-transform: capitalize !important;
    padding: 2px 14px !important;
    font-size: 17px !important;
    font-weight: 500 !important;
}

.modalTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 70%; */
    justify-content: space-around;
    height: 100%;
}

.code_list{
    font-size: 14px !important;
}

.new_countrycode_field{
    height: 44px !important;
}
.new_countrycode_field > div{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
}
.new_countrycode_field input{
    height: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.2) !important;
    border-radius: 0px !important;
}
.new_countrycode_err input{
    height: 100%;
    border: none;
    border-bottom: 1px solid rgba(255, 0, 0, 1) !important;
    border-radius: 0px !important;
}
.new_countrycode_err{
    margin-bottom: 13px !important;
}
.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: var(--language-secondary-color) !important;
    /* border-width: 2px !important; */
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
    overflow: auto !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

@media only screen and (max-width: 600px) {
    .languageCardMainDiv{
        width: 100% !important;
    }
    .languageCardSubDiv{
        width: 78% !important;
    }
    .loginScreenMainDiv{
        grid-template-columns: 100%;
        grid-auto-flow: dense; 
    }
    .left_part{
        display: none;
    }
    .registerCard{
        width: 86%;
    }
}