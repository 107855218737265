.role_box{
    background-color: #fff !important;
    border-radius: 12px;
    padding: 24px;
    width: 80%;
    margin: auto;
}
.register_box{
    background-color: #fff !important;
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    margin: auto;
}
.photos_box{
    background-color: #fff !important;
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    margin: auto;
}
.role_box_header{
    color: var(--text-color-2) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.role_box_header_register{
    color: var(--text-color-2) !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}
.role_box_header svg{
    margin-right: 10px;
    cursor: pointer;
}
.user{
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;
    display: grid;
    place-items: center;
    padding: 24px 0;
    cursor: pointer;
}
.user_active{
    border: 1px solid var(--language-secondary-color) !important;
    background-color: var(--language-secondary-color) !important;
    border-radius: 10px;
    display: grid;
    place-items: center;
    padding: 24px 0;
    cursor: pointer;
}
.title{
    color: var(--text-color-1) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: center;
}
.my_role{
    color: var(--secondary-text-color) !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    margin: 8px 0 !important;
}
.my_role_two{
    color: var(--secondary-text-color) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin: 8px 0 !important;
}
.continue{
    background-color:var(--default-background-color) !important ;
    color: #fff !important;
    text-transform: capitalize !important;
    /* margin-top: 40px !important; */
    padding: 8px 0 !important;
    font-size: 18px !important;
    border: 1px solid var(--default-background-color) !important;
}
.draft_btn{
    background-color:#fff !important ;
    color: var(--default-background-color) !important;
    text-transform: capitalize !important;
    /* margin-top: 40px !important; */
    padding: 8px 0 !important;
    font-size: 18px !important;
    border: 1px solid var(--default-background-color) !important;
}
.whatsapp{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px !important;
}
.whatsapp b{
    margin-left: 4px;
}
.help_text{
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}
.help_number{
    color: var(--border-colour) !important;
    background-color: #BBE15B !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 12px 15px !important;
    border-radius: 8px;
    margin-left: 50px !important;
    cursor: pointer;
}
.cover_image{
    color: var(--border-colour) !important;
    background-color: #BBE15B !important;
    font-size: 15px !important;
    /* font-weight: 600 !important; */
    padding: 1px 6px !important;
    border-radius: 8px;
    /* margin-left: 50px !important; */
    /* cursor: pointer; */
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 44px;
}
.wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}
.active_tab{
    background-color: var(--language-secondary-color) !important;
    padding: 8px 10px;
    min-width: 80px;
    text-align: center;
    width: auto !important;
    color: var(--text-color-1) !important;
    border-radius: 8px !important;
    border: 1px solid  var(--language-secondary-color) !important;
    cursor: pointer;
}
.inactive_tab{
    background-color:#fff !important;
    border: 1px solid  var(--language-secondary-color) !important;
    padding: 8px 10px;
    min-width: 80px;
    text-align: center;
    width: auto !important;
    color: var(--text-color-1) !important;
    border-radius: 8px !important;
    cursor: pointer;
}
.checkbox{
    margin-top: -8px !important;
    color: #374957 !important;
}
.checkbox_content{
    color: var(--secondary-text-color) !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}
.register_btn{
    background-color:var(--default-background-color) !important ;
    color: #fff !important;
    text-transform: capitalize !important;
    margin-top: 7px !important;
    font-size: 18px !important;
}
.verify_num{
    color: var(--secondary-text-color) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 20px 0 10px 0 !important;
}
.enter_otp{
    color: var(--text-color-2) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 20px 0 10px 0 !important;
}
.doc_photo_wrapper{
    text-align: center;
    border: 1px dashed var(--language-secondary-color) !important;
    border-radius: 10px;
    background-color: var(--text-color-3) !important;
    padding: 18px 10px 23px 10px !important;
    margin-top: 25px !important;
}
.add_photo_wrapper{
    text-align: center;
    border: 1px solid var(--language-secondary-color) !important;
    border-radius: 10px;
    background-color: var(--text-color-3) !important;
    padding: 18px 10px 23px 10px !important;
}
.upload_btn{
    background-color: var(--language-secondary-color) !important;
    color: var(--border-colour) !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    border: 1px solid var(--border-colour) !important;
    padding: 7px 30px !important;
    border-radius: 8px !important;
    cursor: pointer;
}
.doc_upload_btn{
    /* background-color: var(--language-secondary-color) !important; */
    color: var(--border-colour) !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    border: 1px solid var(--border-colour) !important;
    padding: 7px 30px !important;
    border-radius: 8px !important;
    cursor: pointer;
}
.radio_wrapper{
    display: flex;
    align-items: center;
    border: 1px solid #969696 !important;
    padding: 0px 20px 0px 4px;
    border-radius: 6px;
    display: inline-block;
    margin-top: 20px !important;
}
.radio_wrapper .MuiButtonBase-root.MuiRadio-root.Mui-checked{
    color: green !important;
}
.upload_images{
    border-radius: 4px !important;
    height: 180px;
    background-color: rgb(197, 197, 197);
    position: relative;
}
.upload_images img{
    border-radius: 4px !important;
    object-fit: contain !important;
}
.delete_part{
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
    cursor: pointer;
}
.delete_part p{
    cursor: pointer;
}
.upload_photo_wrapper{
    text-align: center;
    border: 1px solid var(--language-secondary-color) !important;
    border-radius: 4px !important;
    background-color: var(--text-color-3) !important;
    padding: 8px 10px 16px 10px !important;
}
.SecondsText {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--secondary-text-color);
}
.upload_btn_two{
    background-color: var(--language-secondary-color) !important;
    color: var(--border-colour) !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    border: 1px solid var(--border-colour) !important;
    padding: 7px 30px !important;
    border-radius: 8px !important;
}
.checkbox_two{
    color: #374957 !important;
}
.created_box{
    border: 1px solid #86a3a3 !important;
    border-radius: 12px;
    background-color: #f8f8f8 !important;
    padding: 24px;
    padding-top: 50px;
    margin: 30px 0;
    position: relative;
}
.created_box p{
    font-size: 24px ;
    font-weight: 700;
    color: var(--text-color-2) !important;
    margin-bottom: 12px;
}
.created_box span{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #000 !important;
    letter-spacing: 0.2px;
}
.created_box button{
    border: 1px solid var(--text-color-2)!important;
    background-color: #f8f8f8 !important;
    color: var(--text-color-2)!important;
    text-transform: capitalize;
    padding: 8px 38px;
    font-size: 16px !important;
    font-weight: 400 !important;
}
.property_plan{
    position: absolute;
    top: 0px;
    background-color: #c6ee90 !important;
    padding: 3px 30px 5px 30px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    font-size: 16px !important;
    font-weight: 500 !important;
    z-index: 1;
    overflow: hidden;
}
.single_property{
    padding: 14px 21px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* padding: 0px; */
}
.property_header p{
    font-size: 24px !important;
    font-weight: 700 !important;
    color: var(--text-color-2) !important;
    letter-spacing: 0.2px;
}
.single_property_details{
    display: flex;
    flex-direction: column;
}
.single_property_details span{
    font-weight: 700 !important;
}
.manage_list{
    color: var(--text-color-2) !important;
    background-color: #fff !important;
    border:1px solid var(--border-colour) !important;
    text-transform: capitalize !important;
    padding: 2px 14px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}
.plan_type{
    position: absolute;
    top: 0;
    right: 15px;
    background-color: #64898A !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 6px 30px;
    color: #fff;
    letter-spacing: 0.5;
}
.edit_icon{
    width: 34px;
    height: 32px;
    font-size: 25px;
    border: 1px solid  #64898A !important;
    padding: 3px;
    color: #64898A !important;
    cursor: pointer;
    border-radius: 6px;
}
.del_icon{
    width: 34px;
    height: 32px;
    font-size: 25px;
    border: 1px solid  #f33d3d !important;
    padding: 3px;
    color: #f33d3d !important;
    cursor: pointer;
    margin-left: 12px;
    border-radius: 6px;
}
.single_details_header{
    color: var(--text-color-2) !important;
    font-size: 27px !important;
    font-weight: 600 !important;
}
.price_part{
    float: right !important;
}
.price_part p{
    color: var(--text-color-2) !important;
    font-size: 22px !important;
    font-weight: 500 !important;
}
.price_part h5{
    color: var(--text-color-2) !important;
    font-size: 19px !important;
    font-weight: 500 !important;
    margin-top: 12px;
}
.contact_btns{
    color: var(--text-color-1) !important;
    border: 1px solid var(--text-color-1) !important;
    text-transform: capitalize !important;
    padding: 6px 30px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    background-color: #fff !important;
}
.contact_btns svg{
    margin-right: 8px !important;
}
.click_more{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6) !important;
    cursor: pointer;
    border-radius: 8px;
}
.share_part{
    position: absolute;
    right: 30px;
    bottom: 18px;
    display: flex;
    align-items: center;
    gap: 15px;
}
.share_part p{
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}
.share_part .active_heart svg{
    fill: #374957 !important;
}
.property_sub_details p{
    color: var(--text-color-2) !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.sub_details_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #fff !important;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-top: 15px !important;
    padding: 20px 0;
    gap: 20px;
}
.details_header{
    color: var(--text-color-2) !important;
    font-size: 19px !important;
    font-weight: 600 !important;
}
.details_part{
    padding: 20px !important;
    background-color: #fff !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.read_more{
    text-align: center !important;
    font-weight: 600 !important;
    cursor: pointer;
}
.read_less{
    text-align: center !important;
    font-weight: 600 !important;
    cursor: pointer;
}
.read_active svg{
    transform: rotate(180deg) !important;
}
.contents span{
    color: var(--text-color-2) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.contents p{
    color: var(--text-color-2) !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}
.name_avatar{
    background-color: var(--default-background-color) !important;
    color: #fff;
    padding: 3px 15px;
    font-size: 25px !important;
    border-radius: 4px;
}
.contact_owner{
    color: var(--text-color-2) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.click_more_count{
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
}
.no_data{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.no_data p{
    color: var(--text-color-2) !important;
    font-size: 40px !important;
    font-weight: 600 !important;
}
.no_data span{
    color: var(--text-color-2) !important;
    font-size: 23px !important;
    font-weight: 500 !important;
    cursor: pointer;
}
.location_det{
    color: var(--text-color-2) !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
}
.sort_btn{
    color: #000 !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 17px !important;
}
.sort_btn svg{
    margin-right: 6px !important;
}
.draft_list{
    background-color: #ffa50042;
    color: orange;
    font-weight: 400 !important;
    font-size: 18px !important;
    padding: 3px 10px;
    border-radius: 6px;
}
.pending_list{
    background-color: #0000ff1c;
    color: blue;
    font-weight: 400 !important;
    font-size: 18px !important;
    padding: 3px 10px;
    border-radius: 6px;
}
.active_list{
    background-color: #19e11930;
    color: #18e918;
    font-weight: 400 !important;
    font-size: 18px !important;
    padding: 3px 10px;
    border-radius: 6px;
}
.deactive_list{
    background-color: #ff000021;
    color: red;
    font-weight: 400 !important;
    font-size: 18px !important;
    padding: 3px 10px;
    border-radius: 6px;
}
.search_res_btn{
    color: var(--text-color-1) !important;
    border: 1px solid var(--text-color-1) !important;
    text-transform: capitalize !important;
    padding: 6px 17px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    background-color: #fff !important;
}
.search_res_btn svg{
    margin-right: 8px !important;
    width: 19px !important;
}
.profile_photo{
    text-align: center;
}
.profile_upload_btn{
    /* background-color: var(--language-secondary-color) !important; */
    color: var(--border-colour) !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    /* border: 1px solid var(--border-colour) !important; */
    padding: 7px 30px !important;
    border-radius: 8px !important;
    cursor: pointer;
}
.photo_close{
    position: absolute;
    top: 10px;
    right: 11px;
    font-size: 26px;
    background: #fff;
    border-radius: 50%;
    color: red;
    cursor: pointer;
}
.document_close{
    /* position: absolute;
    top: 0px;
    right: 0px; */
    font-size: 26px;
    background: #fff;
    border-radius: 50%;
    color: red;
    cursor: pointer;
}
.location_select{
    background-color: #f8f8f8 !important;
    border:1px solid var(--language-secondary-color) !important;
    text-transform: capitalize !important;
    color: rgba(0, 0, 0, 0.4) !important;
    text-align: start !important;
    display: block !important;
    font-weight: 500 !important;
}
.location_select_view{
    background-color: #f8f8f8 !important;
    border:1px solid var(--language-secondary-color) !important;
    text-transform: capitalize !important;
    color: rgba(0, 0, 0, 0.4) !important;
    text-align: start !important;
    display: flex !important;
    font-weight: 500 !important;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: default !important;
}
.location_select_view svg{
    font-size: 17px !important;
    color: rgba(0, 0, 0, 0.7) !important;
    cursor: pointer;
}
.single_breadcrums_active{
    padding: 0 5px;
    cursor: pointer;
}
.single_breadcrums_inactive{
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.6);
}
.new_countrycode_field{
    height: 44px !important;
    margin-top: 8px !important;
}
.new_countrycode_field > div{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
}
.new_countrycode_field input{
    height: 100%;
    border: 1px solid rgba(0,0,0,0.2) !important;
    border-radius: 4px !important;
    background-color: #f8f8f8 !important;
}
.new_countrycode_err input{
    height: 100%;
    border: 1px solid rgba(255, 0, 0, 1) !important;
    border-radius: 4px !important;
    background-color: #f8f8f8 !important;
}
.new_countrycode_err{
    margin-bottom: 30px !important;
    margin-top: 8px !important;
}
.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: var(--language-secondary-color) !important;
    /* border-width: 2px !important; */
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
    overflow: auto !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}
.expectedTime_nonactive{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-right: 8px !important;
    cursor:  pointer !important;
}
.expectedTime_active svg{
    margin: 0 !important;
    fill: #fff !important;
}
.expectedTime_nonactive p{
    margin: 0 !important;
}
.expectedTime_nonactive svg{
    margin: 0 !important;
}
.expectedTime_nonactive li:hover{
    background-color: transparent !important;
}

@media only screen and (max-width: 600px) {
    .property_header p{
      font-size: 20px !important;
    }
}